define("discourse/plugins/chat/discourse/initializers/chat-admin-plugin-configuration-nav", ["exports", "discourse/lib/admin-plugin-config-nav", "discourse/lib/plugin-api", "discourse/plugins/chat/admin/components/chat-admin-plugin-actions"], function (_exports, _adminPluginConfigNav, _pluginApi, _chatAdminPluginActions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "discourse-chat-admin-plugin-configuration-nav",
    initialize(container) {
      const currentUser = container.lookup("service:current-user");
      if (!currentUser?.admin) {
        return;
      }
      (0, _pluginApi.withPluginApi)("1.1.0", api => {
        api.addAdminPluginConfigurationNav("chat", _adminPluginConfigNav.PLUGIN_NAV_MODE_TOP, [{
          label: "chat.incoming_webhooks.title",
          route: "adminPlugins.show.discourse-chat-incoming-webhooks"
        }]);
        api.renderInOutlet("admin-plugin-config-page-actions-chat", _chatAdminPluginActions.default);
      });
    }
  };
});